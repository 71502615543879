import React, { useState, useEffect, useContext } from "react"
import { Helmet } from "react-helmet"
import { graphql, navigate } from "gatsby"
import _ from "lodash"
import Img from "gatsby-image"
import { Flex, Text, Button } from "rebass"
import styled from "@emotion/styled"
import Layout from "../components/layout"
import { formatVariantPrice, formatVariantSalePrice } from "../utils/prices"
import StoreContext from "../context/store-context"
import Medusa from "../services/api"

const ProductPageTop = styled(Flex)`
  display: flex;

  justify-content: flex-end;
  align-items: flex-end;

  ${(props) => props.theme.bp.desktop} {
    height: 500px;
    padding-left: 35px;
    padding-right: 35px;
  }

  padding-left: 15px;
  padding-right: 15px;

  height: 400px;

  position: relative;
  text-align: right;

  div {
    font-family: Georgia;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 22pt;
    min-height: 50px;

    ${(props) => props.theme.bp.tablet} {
      font-size: 14px;
      right: 35px;
      width: 600px;
    }

    ${(props) => props.theme.bp.desktop} {
      font-size: 14px;
      right: 35px;
      width: 900px;
    }

    margin-bottom: 15px;
    width: 300px;
    right: 15px;
    bottom: 50px;
  }

  ${(props) => props.theme.bp.mobile} {
    margin-top: 200px;

    div {
      line-height: 16pt;
    }
  }
`

const Container = styled(Flex)`
  flex-wrap: wrap;

  ${(props) => props.theme.bp.mobile} {
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: row;
  }

  ${(props) => props.theme.bp.tablet} {
    gap: 10px;
  }

  ${(props) => props.theme.bp.desktop} {
    gap: 0px;
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: row;
  }
`

const ProductContainer = styled(Flex)`
  flex-direction: column;

  ${(props) => props.theme.bp.mobile} {
    width: 100%;
    margin-bottom: 40px;
    padding-left: 10px;
    padding-right: 10px;
  }

  ${(props) => props.theme.bp.tablet} {
    width: calc(50% - 5px);
  }

  ${(props) => props.theme.bp.desktop} {
    width: calc(33.33%);
    padding-left: 5px;
    padding-right: 5px;
  }

  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

const ProductImage = styled(Img)`
  width: 100%;
  height: 100%;
`

const ProductInfo = styled.button`
  width: 100%;
  font-family: Georgia;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #050d2b;
  background-color: #f4f0e5;
  text-align: left;
  margin-bottom: 10px;
  cursor: pointer;
  border: none;
  min-height: 65px;

  ${(props) => props.theme.bp.desktop} {
    font-size: 14px;
  }

  ${(props) => props.theme.bp.mobile} {
    padding-left: 0px;
  }
`

const ProductGrid = ({ data, location }) => {
  const [inventory, setInventory] = useState(false)
  const { contentfulCollection: collection } = data

  const { cart } = useContext(StoreContext)

  useEffect(() => {
    const vs = collection.products.reduce((acc, next) => {
      return acc.concat(next.variants.map(({ objectId }) => objectId))
    }, [])

    Medusa.variants
      .list({ ids: vs, fields: "inventory_quantity", limit: 1000 })
      .then(({ data }) => {
        const inv = data.variants.reduce((acc, next) => {
          acc[next.id] = next.inventory_quantity
          return acc
        }, {})

        setInventory(inv)
      })
  }, [])

  const isSoldOut = (p) => {
    if (inventory) {
      return !p.variants.some((v) => inventory[v.objectId] > 0)
    }

    return false
  }

  return (
    <Layout
      isCherishedObjects={location.pathname.includes("cherished-objects")}
    >
      <Helmet title={collection.name} />
      <ProductPageTop>
        <Text>
          {collection.description ? collection.description.description : ""}
        </Text>
      </ProductPageTop>
      <Container>
        {collection.products.map((product, i) => {
          return (
            <ProductContainer
              key={i}
              onClick={() => navigate(product.slug)}
              cols={2}
            >
              <ProductImage key={i} fluid={product.thumbnail?.fluid || {}} />
              <ProductInfo>
                <span>{product.title}</span>
                <br />
                <span>
                  {isSoldOut(product) ? (
                    "Sold Out"
                  ) : !formatVariantSalePrice(
                      cart,
                      product.variants[0]
                    ).includes("NaN") ? (
                    <>
                      <del style={{ marginRight: "15px" }}>
                        {formatVariantPrice(cart, product.variants[0])}{" "}
                      </del>
                      {formatVariantSalePrice(cart, product.variants[0])}{" "}
                    </>
                  ) : (
                    <>{formatVariantPrice(cart, product.variants[0])} </>
                  )}
                </span>
              </ProductInfo>
            </ProductContainer>
          )
          // })
        })}
      </Container>
    </Layout>
  )
}

export default ProductGrid

export const query = graphql`
  query($slug: String!) {
    contentfulCollection(slug: { eq: $slug }) {
      id
      slug
      name
      description {
        description
      }
      ...CollectionProducts
    }
  }
`
